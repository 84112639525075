<script setup lang="ts">
    import {defineOptions, reactive} from "vue";
    import {useRoute, useRouter} from 'vue-router'
    import { useStoreContext } from '@stores/useStoreContext';
    import  { useStoreDownload } from "@stores/useStoreDownload.ts"
    import { useStoreUserInfo } from "@stores/useStoreUserInfo.ts";
    import dDownloadIngView from "@components/public/d-downloading-view/d-downloading-view.vue"
    import AcceptCookies from "@components/accept-cookies/accept-cookies.vue";
    import RightSide from "@components/public/right-side/right-side.vue";    // 上下文
    import { useStoreSale } from "@stores/useStoreSale";
    defineOptions({
      name: 'Entry',
    })
    const ctx = useStoreContext();
    const route = useRoute();
    // 下载
    const { downloadList } = useStoreDownload();
    const { userInfo, CHECK_BROWSER_UNIQUE, getAcceptCookieFromStorage } = useStoreUserInfo();
    const data = reactive({
      // 检查唯一性定时器
      checkCancel:null,
      prevUserId: null,
      showAcceptCookie: false
    });

    /* ************************* Hooks ************************* */
    onMounted(() => {
      // console.log('entry mounted')
      const acceptCookie = getAcceptCookieFromStorage();
      if ( !acceptCookie ) {
        data.showAcceptCookie = true;
      }
    })
    /* ************************* Methods ************************* */
    const handleCloseAccept = () => {
        data.showAcceptCookie = false;
    }
    const handleUserChange = async ( newUserId:number, oldUserId:number ) => {
        console.log('handleUserChange', newUserId, oldUserId)
        const { FETCH_SALE_AD_FIND, resetSaleInfo } = useStoreSale();
        const { getToken, isUserReady } = useStoreUserInfo();
        if ( isUserReady ) {
          const token = getToken();
          await FETCH_SALE_AD_FIND({
            token
          });
          // 检查浏览器唯一性
          data.checkCancel = await CHECK_BROWSER_UNIQUE();
        } else {
          resetSaleInfo();
          if ( data.checkCancel ) {
            data.checkCancel();
          }
        }
    }

    /* ************************* Computed ************************* */

    /* ************************* Watch ************************* */
    watch ( userInfo, async (newVal) => {
      console.log('userInfo', newVal)
      const userId = newVal.userid;
      if (userId !== data.prevUserId) {
        await handleUserChange( userId, data.prevUserId );
        data.prevUserId = userId;
      }
    })
</script>

<template>
  <!-- <div>{{ ctx.t }}</div> -->
  <div id="pageContent" :class="[route.name, `pageContent`]">
    <router-view v-slot="{ Component }" >
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <right-side
        :selector="route.meta[`rightBarSelector`] as string"
        :show="!route.meta[`rightBarHide`] as boolean"
        :disabled="route.meta[`rightBarDisabled`] as string[]"
    ></right-side>
    <!-- 下载浮窗 -->
    <dDownloadIngView v-if="false" />
    <!-- 同意cookie -->
    <accept-cookies v-if="data.showAcceptCookie" @close="handleCloseAccept" />
  </div>
</template>

<style lang="scss" module></style>
