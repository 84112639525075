import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export type GcResponse<T> = {
    code: number,
    status: number,
    message: string,
    data: T
}

export class NetworkError<T extends any = GcResponse<any>> extends Error {
    res: T
    message: string
    constructor( res: T ) {
        // @ts-ignore
        const msg = res?.message ?? `Network Error.`;
        super( msg );
        this.res = res;
        this.message = msg ;
    }
}

/**
 * ajax代理函数
 * @param config
 */
export const $axios = async <T extends any = GcResponse<any>> (
    config:AxiosRequestConfig
):Promise<[ NetworkError<T> | null | Error, T | null ]> => {
    try {
        const res = await axios ( config );
        if ( res?.data?.code === 200 || res?.data?.status === 200 ) {
            return [ null, res.data as T ];
        } else {
            throw new NetworkError(res.data) ;
        }
    } catch ( e ) {
        if ( e instanceof NetworkError ) {
            return [ e, e.res];
        } else {
            return [ e as Error, null]
        }
    }
}
