import {$axios, GcResponse} from "../../../helper";
import {ServerStatsPay} from "../../../../lib/constants/server";

export type TStatUserAction = {
    action_uid: number,
    page_url: string,
    action_type: string,
    remark: string
}

/** 通用点击统计
 * @see http://doc.bigbigwork.com/docs/hw/7950
 */
export default function statUserAction (
    params: TStatUserAction
) {
    return $axios<GcResponse<any>> ({
        url: `${ServerStatsPay}/cpUserAction/general/stats`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: JSON.stringify ( params )
    })
}