import { RouteRecordRaw } from "vue-router";
/** 首页 */
const PageIndex = ()=> import('@pages/index/index.vue')
/** 搜索页 */
const PageSearch = ()=> import('@pages/search/search.vue')
/** 大图页 */
const PageImage = ()=> import('@pages/image/image.vue')
/** 用户页 */
const PageUser = ()=> import('@pages/user-center/user-center.vue')
/** 用户页---我的喜爱 */
const PageUserFav = ()=> import('@pages/user-center/my-favorites/my-favorites.vue')
/** 用户页---个人设置 */
const PageUserProfile = ()=> import('@pages/user-center/my-profile/my-profile.vue')
/** 帮助中心----联系我们 */
const PageContact = ()=> import('@pages/help/contact/contact.vue')
/** 协议 */
const PageLegal = ()=> import('@pages/legal/legal.vue')
/** 协议详情 */
const PageLegalDetail = ()=> import('@pages/legal/legal-detail/legal-detail.vue')
/** 帮助 */
const PageHelp = ()=> import('@pages/help/help/help.vue')
/** 帮助详情 */
const PageHelpDetail = ()=> import('@pages/help/help/help-detail/help-detail.vue')
/** 付费套餐 */
const PagePlan = ()=> import('@pages/plan/plan.vue')
/** 付费成功 */
const PagePlanSuccess = ()=> import('@pages/plan/success/success.vue')
/** 测试页面 */
const PageTest = ()=> import('@pages/test/test.vue')
/** 404页面 */
const pageNotFound = ()=> import('@pages/not-found/not-found.vue')

export const routes:RouteRecordRaw[] = [
    {
        path: "/", name: "index", component: PageIndex
    },
    {
        path: "/search", name: "searchIndex", component: PageSearch
    },
    {
        path: "/search/:word", name: "search", component: PageSearch
    },
    {
        path: "/image/:detail(.*)_:id(\\d+).html", name: "image", component: PageImage
    },
    {
        path: "/user",
        name: "user",
        component: PageUser,
        children: [
            {
                path: `my-favorites`,
                name: `favorites`,
                component: PageUserFav
            },
            {
                path: `my-profile`,
                name: `profile`,
                component: PageUserProfile
            },
            {
                path: ``,
                component: PageUserProfile
            }
        ]
    },
    {
        path: "/contact.html", name: "contact", component: PageContact
    },
    {
        path: "/plan/success.html", name: "planSuccess", component: PagePlanSuccess
    },
    {
        path: "/plan", name: "plan", component: PagePlan
    },
    {
        path: "/legal", name: `legal`, component: PageLegal,
        children: [
            {
                path: ``, name: `legalIndex`, component: PageLegalDetail,
            },
            {
                path: `:uuid.html`, name: `legalDetail`, component: PageLegalDetail,
            }
        ]
    },
    {
        path: "/help", name: `help`, component: PageHelp,
        children: [
            {
                path: ``, name: `helpIndex`, component: PageHelpDetail,
            },
            {
                path: `:uuid.html`, name: `helpDetail`, component: PageHelpDetail,
            }
        ]
    },
    // {
    //     path: "/test", name: "test", component: PageTest
    // },
    {
        path: "/404", name: "notFound", component: pageNotFound
    },
    {
        path: "/:catchAll(.*)", redirect: "/404"
    }
]

