const __DEV__ = process.env.DATA_ENV === 'development' ;

export const DEFAULT_TDK = {
    t: 'Millions of PNG Images, Backgrounds and Vectors for Free Download | Clippng',
    d: 'Clippng provides free download of png, png images, backgrounds and vector. Clippng has tens of millions of PNG images and other graphic resources for everyone to download. Using Clippng can help users save time and effectively improve their projects.',
    k: 'png, png images, backgrounds, vector, clipart, icons, free images, free download',
} as const

export const __GOOGLE_APP_ID__ = __DEV__
    ? `369107952076-ce1r3m60suo887erigtjt2pnqm90147q.apps.googleusercontent.com`
    : `1030046759960-7mmod1cn1q8eur5jhqrkdrkmpl776ihg.apps.googleusercontent.com`;