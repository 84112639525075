<template>
  <div :class="$style[`accept-cookies`]">
    <div :class="$style[`rules`]">
      By clicking “Accept”,<br/>you agree Clippng can store cookies on your device and disclose information in accordance with our Cookie Policy.
    </div>
    <div :class="$style[`btn-line`]">
      <d-button :class="[$style[`btn`],$style[`accept`]]" @click="handleAccept(true)">Accept</d-button>
      <d-button :class="[$style[`btn`],$style[`reject`]]" @click="handleAccept(false)">Reject</d-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import dButton from '@components/public/d-button/d-button.vue'
import {useStoreUserInfo} from "@stores/useStoreUserInfo.ts";
const { setAcceptCookie } = useStoreUserInfo();

const emits = defineEmits([`close`]);

/* ******************** Methods ***********************/
const handleAccept = ( value ) => {
  setAcceptCookie ( value );
  emits(`close` );
}
</script>
<style lang="scss" module src="./accept-cookies.scss"></style>