import { createRouter, createWebHistory, createMemoryHistory, RouteRecordRaw, Router } from "vue-router";
import { routes } from "./routes";
import { ROUTE_CONFIGS } from "./meta";
import * as ServerHelpers from "global-server/helpers"
import type { RouteConfigType } from "global-server"

/** 创建路由，并把meta配置到路由 */
export const createAppRouter = (
    options: {
        isSSR: boolean
    },
    routeConfigs: RouteRecordRaw[] = routes,
    metaConfigs: RouteConfigType[] = ROUTE_CONFIGS,
):Router => {
    const { isSSR = false } = options ?? {};
    const distRoutes = ServerHelpers.setupRouteMeta ( routeConfigs, metaConfigs);
    return createRouter({
        history: isSSR ? createMemoryHistory () : createWebHistory(),
        routes: distRoutes,
        scrollBehavior (to, from, savedPosition) {
            // return 期望滚动到哪个的位置
            return savedPosition || { top: 0 }
        }
    });
}