import { showDialogAsync } from "global-common";
import { useStoreUserInfo } from "@stores/useStoreUserInfo";
import { getActivePinia } from "pinia";
import { useStoreRoute } from "@stores/useStoreRoute"
// 是否开放登录功能
export const isUseLogin = () => {
    return true;
    // const isUseLogin = localStorage.getItem(`isUseLogin`);
    // return isUseLogin === `true` ;
}
// 显示登录框
export const showLoginModel = async (
    props?: {step_num?: number, email_address?: string, [name:string]:any },
    opts?: { store:any }
) => {
    if ( isUseLogin() ) {
        const { store = getActivePinia()} = opts || {};
        const { currentRouter } = useStoreRoute();
        const com = ( await import(`@components/dialogs/dialog-login-view/dialog-login-view.vue`) ).default;
        const {} = opts || {};
        const res = await showDialogAsync ( com, true, { propsData: {...props}, router:currentRouter, store } );
        const { setUserInfo, setUserIsFinished } = useStoreUserInfo();
        if ( res?.type === `login` && res?.value?.userInfo ) {
            setUserInfo ( res.value.userInfo );
            setUserIsFinished ( true );
        }
        console.log ( `showLoginModel`, res );
        return res ;
    } else {
        const res = await showContactModel( props );
        return res;
    }

}
// 显示联系我们框
export const showContactModel = async ( props ) => {
    const com = ( await import(`@components/dialogs/dialog-contact/dialog-contact.vue`) ).default;
    const res = await showDialogAsync ( com, true, {propsData: {...props}} );
    return res;
}

