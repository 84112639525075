import {ResultItem} from "global-common";
import {LRUCache} from "lru-cache" ;
import {statOpenPay} from "@helpers/stats.ts";

export const isDev = () => {
    // @ts-ignore
    return __DEV__ || process?.env?.NODE_ENV === `development`;
};

/** 替换协议为相对协议 */
export const toRelativeProtocol = (url: string, newProtocol = `//`) => {
    if (typeof url !== `string` || !url.trim()) return ``;
    if (url.match(/^(blob:)|(data:)/)) return url;
    try {
        return url.replace(/^((http|https):)?\/\//, newProtocol);
    } catch (e) {
        isDev() && console.log(e);
    }
};
/** 检测文本是否溢出 */
export const checkOverflow = (el: any) => {
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === `visible`) {
        el.style.overflow = `hidden`;
    }
    const isOverflowing = el.clientWidth < el.scrollWidth ||
        el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
};

/**
 * @description 截取字符串，中文为2、英文为1，超过长度加上"..."
 * @param str:字符串,n:长度
 */
export const subEngAndCH = function ( str:string, n: number ): string {
    // eslint-disable-next-line no-control-regex
    var r = /[^\x00-\xff]/g ;
    if ( str.replace ( r, `**` ).length <= n ) {
        return str ;
    }
    var m = Math.floor ( n / 2 ) ;
    for ( var i = m ; i < str.length ; i++ ) {
        if ( str.substr ( 0, i ).replace ( r, `**` ).length >= n ) {
            return str.substr ( 0, i - 3 ) + `...` ;
        }
    }
    return str ;
} ;

/** 设置瀑布流显示尺寸(最高500，最小100) **/
export const resizeItem = ( item:ResultItem | ResultItem ):ResultItem => {
    const h = 320 / item.w * item.h ;
    item.dw = 320 ;
    // item.dh = h + 24 ;
    // 8 底部间距
    item.dh = h ;
    item.dh = Math.max ( Math.min ( 500, item.dh ), 100 ) ;
    return item ;
} ;

/** 搜索缓存 */
export const CacheSearch = new LRUCache<string, any> ( {
    max: 1024 * 60,
    maxAge: 1000 * 60 * 60 * 2
} ) ;
/**
 * 购买VIP
 * @param params
 */
export const buyVip = ( params: { fromText:string }) => {
    const { fromText } = params ;
    let url = `/plan` ;
    if ( fromText ) {
        const base64FromText = window.btoa ( unescape(encodeURIComponent(fromText)) ) ;
        url += `?from_text=${encodeURIComponent(base64FromText)}`
    }
    window.open(url, "_blank");
    if ( fromText ) {
        statOpenPay ({ fromText: fromText })
    }
}