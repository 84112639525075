import { isDev, isBundle } from "../helpers/env";
const __DEV__ = process.env.DATA_ENV === 'development' ;
/** 大兔接口 */
export const SERVER_RABBIT_API = __DEV__ ? `//rabbitapi-test.bigbigwork.com` : `//rabbitapi.bigbigwork.com` ;
/** 图片集接口 */
export const SERVER_BOARD_API = __DEV__ ? `//board-test.bigbigwork.com` : `//board.bigbigwork.com` ;
/** 智能聚合搜索 */
export const SERVER_META_SEARCH = __DEV__ ? `//p-test.bigbigwork.com` : `//p.bigbigwork.com` ;
/** 网站图标服务器地址 **/
export const ServerIconNode = `${process.env.CDN_DZ_PATH}/icon/` ;
/** 主要服务器 */
export const ServerMainNode = `//s.bigbigwork.com` ;
/** 图片集服务器 **/
export const ServerBoardServer = __DEV__ ? `//board-test.bigbigwork.com` : `//board.bigbigwork.com` ;
/** www */
export const ServerWww = __DEV__ ? `//www-test.bigbigwork.com` : `//www.bigbigwork.com` ;
/** 海外大兔服务器 */
export const ServerOpenRabbitApi = __DEV__ ? `http://47.96.16.39:9911` : ( typeof window === 'undefined' ? `http://172.21.182.7:6110` : `//rabbitapi.openimage.ai` ) ;
// export const ServerOpenRabbitApi = 'http://47.96.16.39:9911' ;
/** 大谱服务器 */
export const ServerTY = __DEV__ ? `https://ty-test.bigbigwork.com` : `https://ty.bigbigwork.com` ;
/** Clippng 用户 接口 */
export const ServerClippngP = __DEV__ ? `https://p-test.clippng.com` : `https://p.clippng.com` ;
/** Clippng 接口 */
export const ServerClippngImg = __DEV__ ? `https://img-test.clippng.com` : `https://img.clippng.com` ;
/** Clippng 前端 */
export const ServerClippng = __DEV__ ? `https://www-test.clippng.com` : `https://www.clippng.com` ;
/** Clippng 付费接口 */
export const ServerClippngPay = __DEV__ ? `http://pay-test.clippng.com` : `https://pay.clippng.com` ;
/** Clippng 统计接口 */
export const ServerStatsPay = __DEV__ ? `https://stats-test.clippng.com` : `https://stats.clippng.com` ;