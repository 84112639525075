import './style.css'
import { buildApp } from './main'
import type { CusRouteComponent } from './typings/types'
import { useStoreGlobal } from "@stores/useStoreGlobal";
import {useStoreUserInfo} from "@stores/useStoreUserInfo";
import { storeToRefs } from "pinia";
import { useGoogleLogin, checkSupportWEBP } from "global-common";
import {__GOOGLE_APP_ID__} from "@constants/configs";
import { isUseLogin } from "@helpers/ui";
import {useStoreRoute} from "@stores/useStoreRoute.ts";
// @ts-ignore
globalThis.__DEV__ = process.env.DATA_ENV === 'development';
// @ts-ignore
globalThis.process = {
  env: {
    NODE_ENV: process.env.NODE_ENV,
    DATA_ENV: process.env.DATA_ENV,
    HOT: process.env.HOT,
    CDN_PATH: process.env.CDN_PATH,
    CDN_DZ_PATH: process.env.CDN_DZ_PATH,
    CDN_CP_PATH: process.env.CDN_CP_PATH,
    CDN_XCX_PATH: process.env.CDN_XCX_PATH,
    CDN_FONT_PATH: process.env.CDN_FONT_PATH,
    CDN_WIMG_PATH: process.env.CDN_WIMG_PATH,
    VUE_ENV: `client`
  }
};

const { app, store, router } = buildApp();
app.config.globalProperties.process = globalThis.process;

if (window.__INITIAL_STATE__) {
    store.state.value = JSON.parse(JSON.stringify(window.__INITIAL_STATE__) ?? '{}');
}

const global = useStoreGlobal();
const { setRoute } = useStoreRoute ();
// 路由认证权限
const routeAuth = async (to: any, from: any ) => {
    let {
        FETCH_USER_INFO,
        CHECK_BROWSER_UNIQUE,
        getToken
    } = useStoreUserInfo();
    const storeUserInfo = storeToRefs(useStoreUserInfo()) ;
    const {
        isUserReady,
        userIsFinished
    } = storeUserInfo ;

    const token = getToken();
    if ( isUserReady.value ) {
        await CHECK_BROWSER_UNIQUE ();
    } else {
        // 自动登录
        if ( to.meta.autoLogin && !userIsFinished.value ) {
            await Promise.allSettled([
                FETCH_USER_INFO ( { token })
            ])
        }
    }
    // 如果没有登录
    if ( !isUserReady.value ) {
        // 如果没有登录但是需要的登陆
        if ( to.meta.needLogin && !isUserReady.value ) {
            console.log('need login, redirect to login page', to.meta.loginFailRedirect );
            // 如果有登录失败重定向则跳转到重定向页面
            if ( to.meta.loginFailRedirect ) {
                let redirect = `/`;
                if ( typeof to.meta.loginFailRedirect === `function` ) {
                    redirect = to.meta.loginFailRedirect( to );
                } else {
                    redirect = to.meta.loginFailRedirect || `/`;
                }
                return { redirect: redirect };
            } else {
                return { redirect: `/`};
            }
        }
    }
    return null;
}
// app.mixin({
//     async beforeRouteEnter(to, from, next) {
//         await routeAuth ( to, from, next )
//     },
//     async beforeRouteUpdate(to, from, next) {
//         await routeAuth ( to, from, next )
//     }
// })
router.isReady().then( async () => {
    const { getToken } = useStoreUserInfo();
    setRoute ( router.currentRoute.value ) ;
    router.beforeResolve(async (to, from ) => {
        setRoute ( to );
        let diffed = false
        const activated = to.matched.filter((c, i) => {
            return diffed || (diffed = from.matched[i] !== c) || from.path !== to.path || from.query !== to.query
        })

        if (!activated.length) return false;
        const context = {
            url: to.fullPath,
            store: store.state,
            req: {
                url: to.fullPath,
                originalUrl: to.fullPath,
                cookies: {
                    'jhk-personal': getToken() || ''
                },
                headers: {
                    'user-agent': window.navigator.userAgent,
                }
            }
        }
        const redirect = await routeAuth ( to, from );
        if ( redirect?.redirect ) {
            return redirect.redirect;
        }
        await Promise.all(
            activated.map((c) => {
                const routeComponent = c.components?.default as CusRouteComponent
                // @ts-ignore
                if (routeComponent?.asyncData)
                    // @ts-ignore
                    return routeComponent.asyncData({ store, route: to, ctx: context})

                return true
            }),
        )
    })
    app.mount('#app');
    global.getOsInfo(window.navigator.userAgent);
    global.setServer ( false );
    const redirect = await routeAuth ( router.currentRoute.value, null );
    if ( redirect?.redirect ) {
        router.replace( redirect?.redirect )
    }
    const storeUserInfo = useStoreUserInfo();
    const { FETCH_GOOGLE_LOGIN } = storeUserInfo;
    const { isUserReady } = storeToRefs(storeUserInfo) ;
    if ( !isUserReady.value && isUseLogin() ) {
        // 尝试弹出右上角谷歌登录
        const { initGoogleSdk, loadGoogleSdk } = useGoogleLogin();
        await loadGoogleSdk();
        const [err, res] = await initGoogleSdk({
            idConfiguration: {
                client_id: __GOOGLE_APP_ID__,
                callback: async (response: any) => {
                    console.log( `initGoogleSdk`, response);
                    await FETCH_GOOGLE_LOGIN( response );
                }
            },
            usePrompt: true
        });
    }
}).catch( e => {
    throw e;
});
export {
    app,
    store,
    router
}