import { useContext } from "global-common"
import { DEFAULT_TDK } from "../constants/configs"
import { defineStore } from "pinia"

/**
 * 上下文
 */
export const useStoreContext = defineStore( `context`, ()=> {
    const context = useContext();
    context.t.value = DEFAULT_TDK.t;
    context.d.value = DEFAULT_TDK.d;
    context.k.value = DEFAULT_TDK.k;
    return context;
})