import statImgRelatedApi from "global-common/apis/stat-clippng/cpUserAction/imgRelated/stats.ts";
import {useStoreUserInfo} from "@stores/useStoreUserInfo.ts";
import type { TSearchImgRelatedImg } from "global-common/apis/img-clippng/searchImg/relatedImg/list.ts";
import type {
    TSearchImgByText
} from "global-common/apis/img-clippng/searchImg/searchByText/list.ts";
import statSearchApi from "global-common/apis/stat-clippng/cpUserAction/search/stat.ts";
import statImgHitsApi from "global-common/apis/stat-clippng/cpUserAction/imgHits/stats.ts";
import statOpenPayApi from "global-common/apis/stat-clippng/cpUserAction/openPay/stats.ts"
import statGeneralApi from "global-common/apis/stat-clippng/cpUserAction/general/stats.ts";
import {GridItemList} from "@@/structure/GridItemList.ts";
import {useStoreRoute} from "@stores/useStoreRoute.ts";

// 统计相关图片
export const statImgRelated = ( params: TSearchImgRelatedImg ) => {
    const { userInfo } = useStoreUserInfo();
    statImgRelatedApi({
        uid: userInfo.userid ?? -1,
        flag_pay: userInfo.flag_pay,
        remain_vip: userInfo.remain_vip,
        register_days: null,
        img_id: params.img_id,
        p: params.p,
        p_num: params.num
    })
}

/**
 * 统计搜索
 * @param params
 */
export const statSearch = async ( params:TSearchImgByText ) => {
    const { userInfo } = useStoreUserInfo();
    statSearchApi({
        uid: userInfo.userid ?? -1,
        flag_pay: userInfo.flag_pay,
        remain_vip: userInfo.remain_vip,
        register_days: null,
        search_word: params.text,
        p: params.p,
        p_num: params.size
    })
}
/**
 * 统计图片点击
 * @param item
 * @param params
 * @param mainItem
 */
export const statImgHits = async (
    item:GridItemList,
    params: {
        action_type: string,
        page_sort?: number,
        search_word?: string,
        from_page?: string
    },
    mainItem?:GridItemList
) => {
    const { userInfo } = useStoreUserInfo();
    const { route } = useStoreRoute();
    const defaultPrams = {
        search_word: ``,
        from_page: `cp-${route.meta?.from_page ?? ""}`,
        page_sort: 0,
        action_type: ``,
        uid: userInfo.userid,
        action_img_id: item.id,
        origin_type: item.origin_type,
        main_img_id: mainItem?.id,
        from_page_url: window.location.href
    }
    const paramsObj = {
        ...defaultPrams,
        ...params
    }
    await statImgHitsApi( paramsObj ) ;
}

/**
 * 统计打开支付
 * @param params
 */
export const statOpenPay = async ( params: { fromText: string } ) => {
    const { userInfo } = useStoreUserInfo();
    await statOpenPayApi({
        uid: userInfo.userid ?? -1,
        flag_pay: userInfo.flag_pay,
        remain_vip: userInfo.remain_vip,
        register_days: null,
        from_text: params.fromText
    })
}
// 通常点击统计
export const statGeneral = async ( params: { action_type: string, remark:string } ) => {
    const { userInfo } = useStoreUserInfo();
    await statGeneralApi({
        action_type: params.action_type,
        remark: params.remark,
        action_uid: userInfo.userid ?? -1,
        page_url: window.location.href
    });
}