import {$axios, GcResponse} from "../../../helper";
import {ServerStatsPay} from "../../../../lib/constants/server";

export type TStatOpenPay = {
    uid: number,
    flag_pay: number,
    remain_vip: number,
    register_days: number,
    from_text: string
}

/** 付费统计
 * @see http://doc.bigbigwork.com/docs/hw/8077
 */
export default function statOpenPay (
    params: TStatOpenPay
) {
    return $axios<GcResponse<any>> ({
        url: `${ServerStatsPay}/cpUserAction/openPay/stats`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: JSON.stringify ( params )
    })
}