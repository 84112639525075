<template>
  <div
    :class="[$style.d_button, 'select-not', hasBorder?'': $style.border_none, disable ? $style.disable : '', center && $style[`center`]]"
    :style="{'border-radius': borderRadius}">
    <div
        v-show="!disable"
      :class="[$style.d_button_mask, 'd_button_mask']"
      :style="{'border-radius': borderRadius}" />
    <div :class="[$style.d_button_text, 'd_button_text']"><slot /></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DButton",
  props: {
    center: {
      type: Boolean,
      default: true
    },
    "disable": {
      type: Boolean,
      default: false,
      required: false

    },
    "borderRadius": {
      type: String,
      default: '4px',
      required: false
    },
    "hasBorder": {
      type: Boolean,
      default: true,
      required: false
    }
  }
})
</script>

<style module lang="scss">
  .d_button{
    width: 100px;
    height: 38px;
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    background-color: var(--gc-btn-bg);
    &.center {
      .d_button_text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.disable{
      background: #D2D2D2;
      cursor: not-allowed;
      .d_button_mask{
        background-color: rgba(0,0,0,.05);
      }
    }
    &.border_none{
      border: none;
      .d_button_mask{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
    &:hover{
      .d_button_mask{
        display: inline-block;
      }
    }
    .d_button_mask{
      display: none;
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      left: -1px;
      top: -1px;
      z-index: 1;
      background-color: rgba(0,0,0,.1);
    }
    .d_button_text{
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
</style>
