import {$axios, GcResponse} from "../../../helper";
import {ServerClippngImg} from "../../../../lib/constants/server";

export type TSaleAdFind= {
    token: string,
    type?: number
}

export type ISaleAdFindResult = {
    create_at: string,
    update_at: string,
    id: number,
    flag_vip: number,
    banner_title: string,
    banner_img: string,
    right_title: string,
    right_img: string,
    jump_link: string,
    type: number,
    status: number,
}
/** 付费广告
 * @see http://doc.bigbigwork.com/docs/hw/8017
 */
export default function fetchSaleAdFind(
    params: TSaleAdFind
) {
    const {token = ``, type = 1} = params
    return $axios<GcResponse<ISaleAdFindResult[]>> ({
        url: `${ServerClippngImg}/sale/ad/find`,
        method: 'GET',
        headers: {
            token: token
        },
        params: {
            token,
            type
        }
    })
}