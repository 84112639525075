import { Request, Response } from "express" ;
import type { RouteRecord } from "vue-router" ;
import type { RouteConfigType } from "global-server"

export type ClRouteConfig = {
    rightBarSelector?: string,
    rightBarHide?: boolean,
    rightBarDisabled?: string[],
    [name:string]: any
} & RouteConfigType

/**
 * 缓存配置列表 ( 用于配置路由策略 )
 */
export const ROUTE_CONFIGS:ClRouteConfig[] = [
    {
        name: `index`,
        needLogin: false,
        autoLogin: true,
        cacheName: '/index',
        from_page: `网站首页`
    },
    {
        name: `search`,
        needLogin: false,
        autoLogin: true,
        cacheName: ( req, res ) => {
            return `${req.path}`;
        },
        from_page: `素材搜索结果`
    },
    {
        name: `searchIndex`,
        needLogin: false,
        autoLogin: true,
        cacheName: `searchIndex`,
        from_page: `素材搜索结果`
    },
    {
        name: `image`,
        needLogin: false,
        autoLogin: true,
        cacheName: ( req, res ) => {
            return req.url;
        },
        from_page: `素材大图`
    },
    {
        name: `user`,
        needLogin: true,
        autoLogin: true,
        cacheName: '/user',
        from_page: `个人设置`,
        children: [
            {
                name: `profile`,
                needLogin: true,
                autoLogin: true,
                cacheName: '/user/my-profile',
                from_page: `个人设置`
            },
            {
                name: `favorites`,
                needLogin: true,
                autoLogin: true,
                cacheName: '/user/my-favorites',
                from_page: `我的喜爱`
            },
            {
                name: ``,
                needLogin: true,
                autoLogin: true,
                cacheName: '/user/',
                from_page: `个人设置`
            }
        ]
    },
    {
        name: `contact`,
        needLogin: false,
        autoLogin: true,
        cacheName: '/contact.html',
        from_page: `联系我们`
    },
    {
        name: `planSuccess`,
        needLogin: true,
        autoLogin: true,
        cacheName: '/plan/success.html',
        rightBarDisabled: [`sale`]
    },
    {
        name: `plan`,
        needLogin: true,
        autoLogin: true,
        cacheName: '/plan',
        from_page: `付费页`,
        rightBarDisabled: [`sale`]
    },
    {
        name: `legal`,
        needLogin: false,
        autoLogin: true,
        cacheName: '/legal',
        from_page: `协议`,
        children: [
            {
                name: `legalIndex`,
                needLogin: false,
                autoLogin: true,
                cacheName: '/legal/index',
                from_page: `协议`,
            },
            {
                name: `legalDetail`,
                needLogin: false,
                autoLogin: true,
                cacheName: ( req ) => {
                    return req.url;
                },
                from_page: `协议`,
            }
        ]
    },
    {
        name: `help`,
        needLogin: false,
        autoLogin: true,
        cacheName: '/help',
        from_page: `帮助中心`,
        children: [
            {
                name: `helpIndex`,
                needLogin: false,
                autoLogin: true,
                cacheName: '/help/index',
                from_page: `帮助中心`
            },
            {
                name: `helpDetail`,
                needLogin: false,
                autoLogin: true,
                from_page: `帮助中心`,
                cacheName: ( req ) => {
                    return req.url;
                },
            }
        ]
    },
    {
        name: `test`,
        needLogin: false,
        autoLogin: true,
        cacheName: '/test'
    },
    {
        name: `notFound`,
        needLogin: false,
        autoLogin: true,
        cacheName: `notFound`,
        from_page: `404页`,
        rightBarHide: true
    }
]
