<template>
  <div :class="$style[`right-side`]" v-show="show">
    <ol :class="$style[`list`]">
      <!-- 促销 -->
      <li :class="[$style[`li`], $style[`sale`]]"
          v-if="!isDisabled(`sale`) && saleInfo.right_img && !isVip"
          @click="handleSale"
      >
        <img :src="saleInfo.right_img" alt="sale" />
      </li>
      <!-- 反馈 -->
      <li :class="[$style[`li`], $style[`feedback`]]"
          v-if="!isDisabled(`feedback`) && isUserReady" @click="handleTo(`/contact.html`)">
        <inline-svg :src="iconRightFeedBack"></inline-svg>
      </li>
      <!-- 帮助 -->
<!--      <li :class="[$style[`li`], $style[`help`]]"-->
<!--          v-if="!isDisabled(`help`) && isUserReady"-->
<!--          @click="handleTo(`/help`)"-->
<!--      >-->
<!--        <inline-svg :src="iconRightHelp"></inline-svg>-->
<!--      </li>-->
      <!-- 返回顶部 -->
      <li :class="[$style[`li`], $style[`top`]]"
          v-if="!isDisabled(`top`)"
          v-show="showTop"
          @click="scrollToTop"
      >
        <inline-svg :src="iconRightTop"></inline-svg>
      </li>
    </ol>
  </div>
</template>
<script lang="ts" setup>
import iconRightFeedBack from "@images/icon_r_feedback.svg";
import iconRightHelp from "@images/icon_r_help.svg";
import iconRightTop from "@images/icon_r_top.svg";
import {scrollAniTo, BigBigWork} from "global-common";
import { useStoreSale } from "@stores/useStoreSale";
import { useStoreUserInfo } from "@stores/useStoreUserInfo.ts";
import TWEEN from "@tweenjs/tween.js";
import { storeToRefs } from "pinia";
import {buyVip} from "@helpers/utils";

const props = defineProps({
  selector: {
    type: String,
    default: "",
  },
  show: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Array,
    default: () => [], // ['sale', 'feedback', 'help', 'top']
  },
  fromText: {
    type: String,
    default: `右侧栏`
  }
});

/* ****************** Hooks ****************** */
onMounted(() => {
  let el: HTMLElement | Window | null;
  if ( props.selector ) {
    el = document.querySelector(props.selector) as HTMLElement;
  } else {
    el = window;
  }
  if (!el) return;
  scrollEl = el;
  handleScroll();
  el.addEventListener("scroll", handleScroll);
  BigBigWork.on(`RIGHT_SIDE__TO_TOP`, scrollToTop );
});
onUnmounted(() => {
  BigBigWork.off(`RIGHT_SIDE__TO_TOP`, scrollToTop);
  if (!scrollEl) return;
  scrollEl.removeEventListener("scroll", handleScroll);
});

/* ******************** data ****************** */
const { saleInfo } = useStoreSale();
const { isUserReady, isVip } = storeToRefs ( useStoreUserInfo() );
// 滚动条高度
const scrollTop = ref<number>(0);
// 滚动元素
let scrollEl = null as HTMLElement | Window | null;
// 是否显示返回顶部按钮
const showTop = ref<boolean>(false);
const scrollAniCancel = ref<Function | null>(null);
/* ****************** Methods ****************** */
const handleScroll = ( ) => {
  if ( scrollEl === window ) {
    scrollTop.value = window.scrollY;
  } else {
    scrollTop.value = ( scrollEl as HTMLElement ).scrollTop;
  }
  // 如果滚动条大于屏幕的50%，显示返回顶部按钮
  showTop.value = scrollTop.value > window.innerHeight / 2;
};
// 返回顶部
const scrollToTop = () => {
  if ( scrollAniCancel.value ) {
    scrollAniCancel.value();
  }
  scrollAniCancel.value = scrollAniTo( {x:0, y:0}, 300, TWEEN.Easing.Quadratic.Out, scrollEl );
};
const handleSale = () => {
  if ( !saleInfo.jump_link ) {
    buyVip( { fromText: props.fromText})
  } else {
    window.open( saleInfo.jump_link, "_blank");
  }
};
const handleTo = ( path:string ) => {
  window.open(path, "_blank");
}

/* ****************** Computed ****************** */
const isDisabled = (type: string) => props.disabled.includes(type);

/* ****************** Watch ****************** */
watch( props, (newVal, oldVal ) => {
  if ( newVal.selector !== oldVal.selector ) {
    if (scrollEl.value) {
      scrollEl.value.removeEventListener("scroll", handleScroll);
    }
    let el;
    if ( props.selector ) {
      el = document.querySelector(props.selector) as HTMLElement;
    } else {
      el = window;
    }
    if (!el) return;
    scrollEl.value = el;
    el.addEventListener("scroll", handleScroll);
  }
});
</script>
<style lang="scss" module src="./right-side.scss"></style>
