import {$axios, GcResponse} from "../../../helper";
import {ServerStatsPay} from "../../../../lib/constants/server";

export type TStatRelatedImg = {
    uid: number,
    flag_pay: number,
    remain_vip: number,
    register_days: number,
    img_id: number,
    p: number,
    p_num: number
}

/** 图片点击统计
 * @see http://doc.bigbigwork.com/docs/hw/8079
 */
export default function statRelatedImg (
    params: TStatRelatedImg
) {
    return $axios<GcResponse<any>> ({
        url: `${ServerStatsPay}/cpUserAction/imgRelated/stats`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: JSON.stringify ( params )
    })
}