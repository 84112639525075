import Vue, { createSSRApp, createApp } from 'vue'
import { createAppRouter } from "./router"
import {createPinia, defineStore} from "pinia";
import { useRoute } from "vue-router";
import App from './entry.vue'
import InlineSvg from 'vue-inline-svg';
import '@styles/entry.scss'

// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
const isServer = typeof window === 'undefined'

export function buildApp() {
  // 配置全局变量
  globalThis.__DEV__ = process.env.DATA_ENV === 'development';
  globalThis.__BUNDLE__ = process.env.NODE_ENV !== 'development';
  globalThis.__HOT__ = process.env.HOT === 'true';

  // 创建路由
  const isSSR = import.meta.env.SSR;
  const router = createAppRouter ({ isSSR})
  const store = createPinia ();
  const app = isServer ? createSSRApp(App):createApp(App);
  app.config.globalProperties.process = {
    env: import.meta.env
  }
  app.component('inline-svg', InlineSvg);
  app.use ( store );
  app.use ( router );
  return { app, router, store }
}
