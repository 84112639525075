import { defineStore } from "pinia";
import fetchSaleAdFind, { ISaleAdFindResult, TSaleAdFind } from "global-common/apis/img-clippng/sale/ad/find.ts";
import { reactive, ref } from "vue";
import { loadData, saveData } from "global-common";

const defaultSaleInfo: ISaleAdFindResult = {
    create_at: ``,
    update_at: ``,
    id: -1,
    flag_vip: -1,
    banner_title: ``,
    banner_img: ``,
    right_title: ``,
    right_img: ``,
    jump_link: ``,
    type: -1,
    status: -1,
}
export const useStoreSale = defineStore(`Sale`, ()=> {
    // 广告详情
    const saleInfo = reactive<ISaleAdFindResult>({
        ...defaultSaleInfo
    });
    // 是否显示底部banner
    const isShowBottomBanner = ref<boolean> (false );
    // 是否加载完成
    const saleInfoReady = ref<boolean> (false);
    // 重置广告详情
    const resetSaleInfo = () => {
        const keys = Object.keys(defaultSaleInfo);
        keys.forEach((key) => {
            saleInfo[key] = defaultSaleInfo[key];
        });
        saleInfoReady.value = false;
    }
    /** 获取广告详情 */
    const FETCH_SALE_AD_FIND = async ( params: TSaleAdFind) => {
        const [err, res] = await fetchSaleAdFind( params );
        if (err || !res) {
            const error = err || new Error(`FETCH_SALE_AD_FIND error`);
            return [error, null]
        }
        const data = res.data[0]
        if (!data) return [null, null]
        const keys = Object.keys(saleInfo);
        keys.forEach((key) => {
            saleInfo[key] = data[key];
        });
        saleInfoReady.value = true;
        return [null, res]
    }
    // 获取是否底部banner配置
    const getBottomBannerConfig = () => {
        const isShow = loadData ( `isShowBottomBanner`, `` ) ?? true ;
        isShowBottomBanner.value = !!isShow;
        return isShowBottomBanner.value;
    }
    // 保存是否底部banner配置
    const setBottomBannerConfig = ( value:boolean ) => {
        isShowBottomBanner.value = value;
        saveData ( `isShowBottomBanner`, ``, isShowBottomBanner.value ) ;
    }
    return {
        saleInfo, FETCH_SALE_AD_FIND, resetSaleInfo,
        saleInfoReady, isShowBottomBanner,
        getBottomBannerConfig, setBottomBannerConfig
    }
});
