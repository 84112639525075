import {$axios, GcResponse} from "../../../helper";
import {ServerStatsPay} from "../../../../lib/constants/server";

export type TStatSearch = {
    uid: number,
    flag_pay: number,
    remain_vip: number,
    register_days: number,
    search_word: string,
    p: number,
    p_num: number
}

/** 搜索统计
 * @see http://doc.bigbigwork.com/docs/hw/8065
 */
export default function statSearch (
    params: TStatSearch
) {
    return $axios<GcResponse<any>> ({
        url: `${ServerStatsPay}/cpUserAction/search/stats`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: JSON.stringify ( params )
    })
}