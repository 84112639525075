import {$axios, GcResponse} from "../../../helper";
import {ServerStatsPay} from "../../../../lib/constants/server";

export type TStatImgHits = {
    uid: number,
    action_img_id: number,
    action_type: string,
    origin_type: number,
    from_page: string,
    from_page_url: string,
    page_sort: number,
    main_img_id: number,
    search_word: string
}

/** 图片点击统计
 * @see http://doc.bigbigwork.com/docs/hw/6875
 */
export default function statImgHits (
    params: TStatImgHits
) {
    return $axios<GcResponse<any>> ({
        url: `${ServerStatsPay}/cpUserAction/imgHits/stats`,
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        data: JSON.stringify ( params )
    })
}